<template>
  <Ibox :title="'Szczegóły'">
    <template slot="content" slot-scope="{ setLoading }">
      <Form slot="content" @loading="setLoading" />
    </template>
  </Ibox>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import Form from "@/components/Products/Form.vue";

export default {
  name: "DetailsFormComponent",
  components: {
    Ibox,
    Form
  }
};
</script>
