<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <form role="form">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Nazwa</label>
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              v-model="product[`name_${$route.params.shop_lang}`]"
            />
          </div>
        </div>
        <!-- <div v-for="(item, i) in fotoArr" :key="i" class="form-group row">
          <label class="col-sm-2 col-form-label">{{ item }}</label>
          <div class="col-sm-10">
            <img
              :src="
                `${$route.params.shop_name}.${$route.params.shop_lang}/${item}-
                ${product.id}/${product.key_pl}.${createSubstr(product[item])}`
              "
              alt=""
              width="100"
              height="100"
              class="img"
            />
          </div>
        </div> -->
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Zdjęcia</label>
          <div class="col-sm-10">
            <b-button
              @click="changeFotoTitle"
              v-b-toggle="'collapse-2'"
              class="m-1"
              >{{ fotoTitle ? "Ukryj" : "Pokaż zdjęcia" }}
            </b-button>
            <b-collapse id="collapse-2">
              <b-card style="width: auto; height:auto;">
                <b-carousel
                  id="carousel-1"
                  v-model="slide"
                  :interval="3000"
                  controls
                  indicators
                  background="white"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <!-- Text slides with image -->
                  <b-carousel-slide
                    v-for="(item, i) in fotoArr"
                    :key="i"
                    :text="`${item}`"
                    style="text-shadow: 1px 1px 2px black; font-size: 1vw;"
                  >
                    <template #img>
                      <img
                        class="d-block img-fluid w-auto"
                        style="text-shadow: 1px 1px 2px black; font-size: 3vw;"
                        width="1024"
                        height="480"
                        :src="
                          `https://www.${$route.params.shop_name}.${
                            $route.params.shop_lang
                          }/${item}-${product.id}/${
                            product.key_pl
                          }.${createSubstr(product[item])}`
                        "
                        alt="image slot"
                      />
                    </template>
                  </b-carousel-slide> </b-carousel
              ></b-card>
              <div
                v-for="(size, i) in fotoSizes"
                :key="i"
                class="form-group row"
              >
                <label class="col-sm-2">Dodaj {{ size }} </label>
                <input
                  class="col-sm-10"
                  type="file"
                  :name="`${size}`"
                  alt="Dodaj Zdjęcie"
                  @change="onFileChange($event, size)"
                  accept=".png, .jpg, .jpeg"
                />
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Cena A</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="product.priceAg" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Cena B</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="product.priceBg" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Cena C</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="product.priceCg" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Cena D</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="product.priceDg" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Cena za ml</label>
          <div class="col-sm-10">
            <span class="form-control">{{ mlCount }}</span>
          </div>
        </div>
        <div v-if="product.vote_rate != 0" class="form-group row">
          <label class="col-sm-2 col-form-label">Ocena</label>
          <div class="col-sm-10">
            <span class="form-control">{{ product.vote_rate }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Opis</label>
          <div class="col-sm-10">
            <textarea
              type="text"
              class="form-control"
              v-model="product[`desc_search_${$route.params.shop_lang}`]"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Opis-rzeczywisty wygląd</label>
          <div class="col-sm-9">
            <span v-html="product.desc_pl"></span>
          </div>
        </div>
        <div v-if="!showDescEdit" class="form-group row">
          <div @click="editDesc" class="btn btn-primary">
            Edytuj kod opisu
          </div>
        </div>
        <div v-if="showDescEdit" class="form-group row">
          <label class="col-sm-2 col-form-label">Edytuj kod opisu</label>
          <div class="col-sm-10">
            <textarea
              type="text"
              class="form-control"
              v-model="product.desc_pl"
            />
          </div>
        </div>
        <div v-if="showDescEdit" class="form-group row">
          <div @click="editDesc" class="btn btn-primary">
            Zapisz kod
          </div>
        </div>
      </form>
      <div class="row">
        <div @click="saveChange" class="btn btn-primary">
          Zapisz zmiany
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      product: {
        name: "",
        priceAg: "",
        priceBg: "",
        priceCg: "",
        priceDg: "",
        desc_search_pl: "",
        vote_rate: "",
        fotoa: "",
        fotob: "",
        fotos: "",
        fotom: "",
        fotoArr: []
      },
      showDescEdit: false,
      fotoTitle: false,
      fotoSizes: ["fotoa", "fotos", "fotom", "fotob"]
    };
  },
  computed: {
    mlCount: function() {
      let numer =
        Number(this.product.priceAg) / (Number(this.product.weight) * 100);
      if (typeof numer !== "number") {
        return;
      } else {
        return numer.toFixed(2) + " zł";
      }
    }
  },
  created() {
    this.getData();
  },
  methods: {
    onFileChange(event, size) {
      this.product[size] = event.target.files[0].name;
    },
    editDesc() {
      if (!this.showDescEdit) this.showDescEdit = true;
      else this.showDescEdit = false;
    },
    changeFotoTitle() {
      if (!this.fotoTitle) this.fotoTitle = true;
      else this.fotoTitle = false;
    },
    getData() {
      this.$emit("loading", true);
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/products/${this.$route.params.id}`;
      this.$http
        .get(url)
        .then(resp => {
          console.log(resp, "response");
          this.product = resp.data;
          this.fotoAr();
          this.$emit("loading", false);
        })
        .catch(err => {
          this.$toastr.error(err);
          this.$emit("loading", false);
        });
    },
    createSubstr(str) {
      let splitedStr = str.split(".");
      return splitedStr.at(-1);
    },
    fotoAr() {
      let arr = [];
      if (this.product.fotoa !== "") arr.push("fotoa");
      if (this.product.fotob !== "") arr.push("fotob");
      if (this.product.fotos !== "") arr.push("fotos");
      if (this.product.fotom !== "") arr.push("fotom");
      this.fotoArr = arr;
    },
    saveChange() {
      this.$emit("loading", true);
      this.$http({
        url: `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/products/${this.$route.params.id}`,
        method: "put",
        data: this.product
      }).then(resp => {
        console.log(resp);
        this.$toastr.succes("Zrobione!");
      });
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
};
</script>
